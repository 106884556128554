import React, { useContext } from "react";

import { CartContext } from "../contexts/Cart/CartContext";
import { object } from "prop-types";

const PelletCard = ({ ...props }) => {
  const product = props.product;
  const { increase, addProduct, cartItems } = useContext(CartContext);

  const getCartCount = () =>
    cartItems.reduce((total, item) => total + item.quantity, 0);
  const increaseProduct = (product) => {
    const itemExists = cartItems.find((prod) => prod.id === product.id);

    if (getCartCount() < 10) {
      if (itemExists) {
        if (itemExists.quantity < 10) {
          increase(product);
        }
      } else {
        if (cartItems.length < 10) {
          addProduct(product);
        }
      }
    }
  };

  return (
    <article class="relative flex flex-col overflow-hidden rounded-lg border mb-8">
      <div class="aspect-square overflow-hidden">
        <img
          class="h-full w-full object-cover transition-all duration-300 group-hover:scale-125"
          alt={product.title}
          src={window.location.origin + "/products/" + product.image}
        />
      </div>
      {product.discount === 2 && (
        <div class="absolute top-0 m-2 rounded-full">
          <p class="rounded-full bg-[#FD5E33] p-1 text-[12px] font-bold uppercase tracking-wide text-white sm:py-1 sm:px-3">
            TOPPER
          </p>
        </div>
      )}
      {product.discount === 3 && (
        <div class="absolute top-0 m-2 rounded-full">
          <p class="rounded-full bg-[#FD5E33] p-1 text-[12px] font-bold uppercase tracking-wide text-white sm:py-1 sm:px-3">
            Meest verkocht
          </p>
        </div>
      )}
      <div class="my-2 mx-auto flex w-10/12 flex-col items-start justify-between h-48">
        <h3 class="mb-2 text-lg text-gray-800">{product.title}</h3>
        <div className="flex items-center space-x-1 rtl:space-x-reverse">
          {product.characteristics.replaceAll(";", ", ")}
        </div>
        <div class="mt-4 flex">
          {product.discount === 1 ? (
            <div>
              <p class="mr-3 text-lg font-semibold">
                €{product.price - product.discount}
              </p>
              <del class="text-xs text-gray-400"> €{product.price} </del>
            </div>
          ) : (
            <div>
              <p class="mr-3 text-lg font-semibold text-gray-800">
                €{product.price}{" "}
                <span class="text-xs text-gray-600">
                  (€{product.unitprice} per zak)
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      {product.amount > 0 ? (
        <button
          class="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600"
          onClick={() => increaseProduct(product)}
        >
          <div class="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-orange-500 group-hover:text-white">
            Bestellen
          </div>
          <div class="flex items-center justify-center bg-gray-200 px-5 transition group-hover:bg-orange-400 group-hover:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
          </div>
        </button>
      ) : (
        <p class="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600">
          Enkel afgehaald
        </p>
      )}
    </article>
  );
};

PelletCard.propTypes = {
  product: object.isRequired,
};

export default PelletCard;
