import React, { useContext } from "react";

import { CartContext } from "../../contexts/Cart/CartContext";
import { object } from "prop-types";

const CartItem = ({ ...props }) => {
  const product = props.product;
  const { removeProduct } = useContext(CartContext);

  return (
    <li class="flex py-6">
      <div class="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200v">
        <img
          class="h-full w-full object-cover object-center"
          alt={product.title}
          src={window.location.origin + "/products/" + product.image}
        />
      </div>

      <div class="ml-4 flex flex-1 flex-col">
        <div>
          <div class="flex justify-between text-base font-medium text-gray-900">
            <h3>{product.title}</h3>
            <p class="ml-4">€ {product.price * product.quantity}</p>
          </div>
        </div>
        <div class="flex flex-1 items-end justify-between text-sm">
          <p class="text-gray-500">{product.quantity}</p>

          <div class="flex">
            <button
              type="button"
              class="font-medium text-indigo-600 hover:text-indigo-500"
              onClick={() => removeProduct(product)}
            >
              Verwijderen
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

CartItem.propTypes = {
  product: object.isRequired,
};

export default CartItem;
