import React, { useState, useEffect } from "react";
import Pellets from "./../component/sections/Pellets";
import api from "../api";

const PelletPage = () => {
  const [pellets, setPellets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && pellets.length === 0) {
      setLoading(true);

      api.getProductData().then((response) => {
        response.data.forEach((item) => {
          item.price = parseFloat(item.price);
          item.discount = parseFloat(item.discount);
          item.discountPrice =
            parseFloat(item.price) - parseFloat(item.discount);
          item.amount = parseFloat(item.amount);
          item.pallet = parseFloat(item.pallet);
          item.sale = item.sale === "1";
          item.stock = item.stock === "1";
          item.characteristics = item.characteristics.split(";");
        });

        response.data = response.data.filter((item) => item.sale);
        setPellets(response.data);
        setLoading(false);
      });
    }
  }, [pellets, setPellets, loading, setLoading]);

  return (
    <>
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto content-center grid-cols-1 content-center">
        <p class="mx-auto mt-2 max-w-lg text-pretty text-center text-4xl font-medium tracking-tight text-[#212573] sm:text-5xl">
          Onze Pellets
        </p>
        <p class="mx-auto">
          Gelieve vooraf te bellen bij afhaling van een volledige pallet.
        </p>
      </div>

      <Pellets />

      <div className="grid max-w-screen-xl px-4 py-8 mx-auto content-center grid-cols-1 content-center">
        <p class="mx-auto">
          * Prijzen pellets zijn per pallet en bij afhaling.
        </p>
        <img
          className="p-2 mx-auto rounded-sm h-32"
          alt="EcoCheque afbeelding"
          src={window.location.origin + "/assets/ecocheque.png"}
        />
        <p class="mx-auto">
          Wij aanvaarden ecocheques voor alle pellet aankopen (max 250€)
        </p>
      </div>
    </>
  );
};

export default PelletPage;
