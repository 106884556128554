import React, { useContext } from "react";

import { CartContext } from "../contexts/Cart/CartContext";

const Header = (...props) => {
  const { itemCount } = useContext(CartContext);
  const childFunction = () => props[0].functionCallFromParent();

  return (
    <div>
      <nav className="sticky bg-[#212573] fixed">
        <div class="mx-auto max-w-7xl text-white">
          <div class="relative flex h-16 items-center justify-between">
            <div class="flex flex-1 px-4 md:px-0 lg:items-stretch sm:justify-start">
              <div class="flex flex-shrink-0 text-white">
                <h1 className="text-white text-xl">Brandstoffen Geert Roels</h1>
              </div>
            </div>
            <div class="absolute inset-y-0 flex items-center pr-4 right-0 md:mr-8 lg:mr-0 sm:static sm:inset-auto sm:ml-6">
              <div
                class="relative py-2 cursor-pointer"
                onClick={() => childFunction()}
              >
                <div class="t-0 absolute left-3">
                  <p class="flex h-2 w-2 items-center justify-center rounded-full bg-red-500 p-3 text-xs text-white">
                    {itemCount}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="file: mt-4 h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Header;
