import React, { useState } from "react";

import PelletCard from "../PelletCard";
import api from "../../api";

const PelletPage = () => {
  const [pellets, setPellets] = useState([]);

  if (pellets.length === 0) {
    api.getProductData().then((response) => {
      response.data.forEach((item) => {
        item.price = parseFloat(item.price);
        item.discount = parseFloat(item.discount);
        item.discountPrice = parseFloat(item.price) - parseFloat(item.discount);
        item.amount = parseFloat(item.amount);
        item.pallet = parseFloat(item.pallet);
      });

      response.data = response.data.filter((item) => item.sale);
      setPellets(response.data);
    });
  }

  return (
    <div className="grid max-w-screen-xl px-4 py-8 mx-auto content-center grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:md:grid-cols-4 justify-center gap-8 content-center">
      {pellets.map((pellet) => (
        <PelletCard key={pellet.id} product={pellet} />
      ))}
    </div>
  );
};

export default PelletPage;
