import React from "react";
import styled from "styled-components";
import backgroundImg from "../assets/background.png";
import splashSVG from "../assets/icons/landing.svg";

import { Row, Col, Image, Typography } from "antd";

const SplashImage = styled.div`
  width: 100%;

  position: relative;
  background-image: url(${splashSVG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;

  min-height: 45vh;

  @media (min-width: 375px) {
    .title {
      margin: 20px 30px;
    }
  }

  @media (min-width: 768px) {
    .title {
      margin: 0;
    }
  }
`;

const { Title } = Typography;

function Splash() {
  return (
    <SplashImage>
      <Row align="middle">
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <Title className="vlightblue title">
            Met onze kooiaap brengen we de beste pellets tot (net niet) naast
            jouw kachel.
          </Title>
          <Title className="vlightblue" level={5}>
            <br />
          </Title>
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 2 }} />
        <Col
          xs={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 0 }}
          style={{ paddingTop: 30 }}
        >
          <Image width="100%" src={backgroundImg} preview={false} />
        </Col>
      </Row>
    </SplashImage>
  );
}
export default Splash;
